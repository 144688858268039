import React, { useState } from "react";
import ArrowLeftImg from "../assets/ic-arrow-left.png";
import ArrowRightImg from "../assets/ic-arrow-right.png";
import { constant } from "../constants/constants";

const MainSection04 = ({ isMobile = false }) => {
    const slides = constant.mainsection04_slider;
    const [slideIndex, setSlideIndex] = useState(0);

    const moveToPrevSlide = () => {
        if (slideIndex !== 0) {
            setSlideIndex((prev) => prev - 1);
        }
    };

    const moveToNextSlide = () => {
        if (slideIndex !== slides.length - 1) {
            setSlideIndex((prev) => prev + 1);
        }
    };

    const [startX, setStartX] = useState(0);
    const [endX, setEndX] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    const handleStart = (x) => {
        setStartX(x);
        setIsDragging(true);
    };

    const handleMove = (x) => {
        if (isDragging) {
            setEndX(x);
        }
    };

    const handleEnd = () => {
        setIsDragging(false);
        if (startX - endX > 50) {
            moveToNextSlide();
        } else if (endX - startX > 50) {
            moveToPrevSlide();
        }
    };

    return (
        <div
            className="sg-main-section-04-container-sm  md:sg-main-section-04-container-md"
            onTouchStart={(e) => handleStart(e.touches[0].clientX)} // 모바일 터치 시작
            onTouchMove={(e) => handleMove(e.touches[0].clientX)} // 모바일 터치 이동
            onTouchEnd={handleEnd} // 모바일 터치 종료
            onMouseDown={(e) => handleStart(e.clientX)} // 마우스 클릭 시작
            onMouseMove={(e) => handleMove(e.clientX)} // 마우스 이동
            onMouseUp={handleEnd} // 마우스 클릭 종료
        >
            <div className="sg-main-section-04-slider-box">
                <div className="sg-main-section-04-slider-title">나만의 연습장 소장하기</div>
                <div className="sg-main-section-04-slider-box">
                    <div className="sg-main-section-04-slider-text-box">
                        <img
                            src={slides[slideIndex].textIcon}
                            alt="디스크립션 아이콘"
                            className="sg-main-section-04-slider-text-icon"
                        />
                        <div className="sg-main-section-04-slider-text">
                            {slides[slideIndex].text}
                        </div>
                    </div>
                    <div className="sg-main-section-04-slider">
                        <button
                            className={`sg-main-section-04-slider-btn ${
                                slideIndex === 0 ? "disabled" : null
                            }`}
                            onClick={moveToPrevSlide}
                        >
                            <img src={ArrowLeftImg} alt="슬라이드 왼쪽 버튼" />
                        </button>
                        <div className="sg-main-section-04-slider-img-stack-sm md:sg-main-section-04-slider-img-stack-md">
                            {slides.map((slide) => (
                                <div
                                    className={`sg-main-section-04-slider-img ${
                                        slide.key === slideIndex ? "active" : null
                                    }`}
                                    key={slide.key}
                                >
                                    <img
                                        src={isMobile ? slide.imgSm : slide.img}
                                        alt="슬라이드 이미지"
                                    />
                                </div>
                            ))}
                        </div>
                        <button
                            className={`sg-main-section-04-slider-btn ${
                                slideIndex === slides.length - 1 ? "disabled" : null
                            }`}
                            onClick={moveToNextSlide}
                        >
                            <img src={ArrowRightImg} alt="슬라이드 오른쪽 버튼" />
                        </button>
                    </div>
                </div>
                <div className="sg-main-section-04-slider-dot-box">
                    {slides.map((slide) => (
                        <div
                            className={`sg-main-section-04-slider-dot ${
                                slide.key === slideIndex ? "active" : null
                            }`}
                            key={slide.key}
                        />
                    ))}
                </div>
                <div
                    className={`sg-main-section-04-slider-ver2-text ${
                        slides[slideIndex].isVer2 ? "active" : null
                    }`}
                >
                    2.0 업데이트에서 만나요!
                </div>
            </div>
        </div>
    );
};

export default MainSection04;
