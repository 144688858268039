import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import DownloadImg from "../assets/ic-download.png";
import LogoImg from "../assets/logo.png";
import { constant } from "../constants/constants";

const Header = ({ forMain = false, isScrolled = false, isMobile = false, isIOS = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        if (isMobile) {
            if (isIOS) {
                window.location.href = constant.AppStore;
            } else {
                window.location.href = constant.PlayStore;
            }
        } else {
            setIsOpen(!isOpen);
        }
    };

    return (
        <div
            className={`sg-px-320-sm md:sg-px-320-md sg-header ${
                forMain && isScrolled ? "forMain" : null
            }`}
        >
            <Link to="/">
                {!isMobile && (
                    <img
                        src={LogoImg}
                        alt="사각 로고"
                        className={`sg-header-logo-md ${forMain ? "forMain" : null}`}
                    />
                )}

                {isMobile && <img src={LogoImg} alt="사각 로고" className={`sg-header-logo`} />}
            </Link>

            {forMain && (
                <div ref={dropdownRef} className="relative inline-block text-left">
                    <button onClick={toggleDropdown} className="sg-download-btn">
                        <img src={DownloadImg} alt="다운로드" className="sg-header-download-icon" />
                        앱 다운로드
                    </button>

                    {isOpen && (
                        <div className="sg-store-dropdownbox">
                            <a href={constant.PlayStore} className="sg-store-dropdownbox-button">
                                Android
                            </a>
                            <a href={constant.AppStore} className="sg-store-dropdownbox-button">
                                iOS
                            </a>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Header;
