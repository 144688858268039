import React, { useEffect, useState } from "react";
import IpadImg from "../assets/ipadmock.png";
import IpadImg1_SM from "../assets/ipadmock1_sm.png";
import IpadImg2 from "../assets/ipadmock2.png";
import IpadImg2_SM from "../assets/ipadmock2_sm.png";
import MultiLineImg from "../assets/multiLine.png";
import MultiLineImg_SM from "../assets/multiLine_sm.png";
import MyeonjoImg from "../assets/myeonjo.png";
import MyeonjoImg_SM from "../assets/myeonjo_sm.png";
import NeoSansImg from "../assets/neosans.png";
import NeoSansImg_SM from "../assets/neosans_sm.png";
import SchoolImg from "../assets/school.png";
import SchoolImg_SM from "../assets/school_sm.png";
import SingleLineImg from "../assets/singleLine.png";
import SingleLineImg_SM from "../assets/singleLine_sm.png";
import FontSetComponent from "./FontSetComponent";
import WriteModeSetComponent from "./WriteModeSetComponent";

const BookSetting = ({ isMobile = false }) => {
    const fontImgList = isMobile
        ? [MyeonjoImg_SM, NeoSansImg_SM, SchoolImg_SM]
        : [MyeonjoImg, NeoSansImg, SchoolImg];

    const writeSetImgList = isMobile
        ? [MultiLineImg_SM, SingleLineImg_SM]
        : [MultiLineImg, SingleLineImg];

    const [currentFontSrc, setCurrentFontSrc] = useState(fontImgList[0]);
    const [currentBookIndex, setCurrentBookIndex] = useState(0);

    const [currentWriteSrc, setCurrentWriteSrc] = useState(writeSetImgList[0]);
    const [currentWriteIndex, setCurrentWriteIndex] = useState(0);

    const [fade, setFade] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(false);

            setTimeout(() => {
                setCurrentBookIndex((prevIndex) => (prevIndex + 1) % fontImgList.length);
                setCurrentWriteIndex((prevIndex) => (prevIndex + 1) % writeSetImgList.length);
                setFade(true);
            }, 1000);
        }, 3000);

        return () => clearInterval(interval);
    }, [fontImgList.length, writeSetImgList.length]);

    useEffect(() => {
        setCurrentFontSrc(fontImgList[currentBookIndex]);
        setCurrentWriteSrc(writeSetImgList[currentWriteIndex]);
    }, [currentBookIndex, fontImgList, currentWriteIndex, writeSetImgList]);

    return (
        <>
            {!isMobile && (
                <div>
                    <div className="sg-font-setting-container">
                        <div className="sg-font-mock-up-01-container">
                            {/* 서체 목업 */}
                            <div className="sg-font-mock-up-01-img-box">
                                <img
                                    src={IpadImg}
                                    alt="아이패드 목업"
                                    className="w-full h-full object-cover"
                                />
                                <img
                                    src={currentFontSrc}
                                    alt="서체 원고지"
                                    className={`absolute inset-0 w-full h-full object-cover ${
                                        fade ? "opacity-100" : "opacity-0"
                                    }`}
                                />
                            </div>
                            {/* 서체 설정 */}
                            <FontSetComponent currentBookIndex={currentBookIndex} fade={fade} />
                        </div>
                        <div>
                            <div className="sg-tx-title-md sg-mb-20">교본 서체</div>
                            <div className="sg-tx-subtitle-md">
                                다채로운 서체를 통해, <br />
                                원하는 느낌의 손글씨를 연습합니다.
                            </div>
                        </div>
                    </div>
                    <div className="flex  justify-center sg-gap-60 items-center mt-[100px]">
                        <div>
                            <div className="sg-tx-title-md sg-mb-20">교본 설정</div>
                            <div className="sg-tx-subtitle-md">
                                쓰기 방향, 방식, 글자 크기를 <br />
                                조절하여 편안한 연습 환경을 <br />
                                제공합니다.
                            </div>
                        </div>
                        <div className="sg-font-mock-up-02-container">
                            {/* 쓰기 설정 목업 */}
                            <div className="sg-font-mock-up-02-img-box">
                                <img
                                    src={IpadImg2}
                                    alt="아이패드 목업"
                                    className="w-full h-full object-cover"
                                />
                                <img
                                    src={currentWriteSrc}
                                    alt="쓰기 설정 원고지"
                                    className={`absolute inset-0 w-full h-full object-cover ${
                                        fade ? "opacity-100" : "opacity-0"
                                    }`}
                                />
                            </div>
                            {/* 설정 팝업 */}
                            <WriteModeSetComponent
                                currentWriteIndex={currentWriteIndex}
                                fade={fade}
                            />
                        </div>
                    </div>
                </div>
            )}

            {isMobile && (
                <div className="flex flex-col items-center">
                    <div className="mb-[64px] flex flex-col items-center">
                        <div className="sg-font-mock-up-01-container-sm">
                            {/* 서체 설정 */}
                            <FontSetComponent
                                isMobile={isMobile}
                                currentBookIndex={currentBookIndex}
                                fade={fade}
                            />
                            {/* 서체 목업 */}
                            <div className="sg-font-mock-up-01-img-box-sm">
                                <img
                                    src={IpadImg1_SM}
                                    alt="아이패드 목업"
                                    className="w-full h-full object-cover"
                                />
                                <img
                                    src={currentFontSrc}
                                    alt="서체 원고지"
                                    className={`absolute inset-0 w-full h-full object-cover ${
                                        fade ? "opacity-100" : "opacity-0"
                                    }`}
                                />
                            </div>
                        </div>
                        <div className="sg-tx-title-sm mb-[10px]">교본 서체</div>
                        <div className="sg-tx-subtitle-sm text-center">
                            다채로운 서체를 통해, <br />
                            원하는 느낌의 손글씨를 연습합니다.
                        </div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="sg-font-mock-up-02-container-sm">
                            {/* 쓰기 설정 목업 */}
                            <div className="sg-font-mock-up-01-img-box-sm">
                                <img
                                    src={IpadImg2_SM}
                                    alt="아이패드 목업"
                                    className="w-full h-full object-cover"
                                />
                                <img
                                    src={currentWriteSrc}
                                    alt="쓰기 설정 원고지"
                                    className={`absolute inset-0 w-full h-full object-cover ${
                                        fade ? "opacity-100" : "opacity-0"
                                    }`}
                                />
                            </div>
                            {/* 설정 팝업 */}
                            <WriteModeSetComponent
                                isMobile={isMobile}
                                currentWriteIndex={currentWriteIndex}
                                fade={fade}
                            />
                        </div>
                        <div className="sg-tx-title-sm mb-[10px]">교본 설정</div>
                        <div className="sg-tx-subtitle-sm text-center">
                            쓰기 방향, 방식, 글자 크기를 조절하여
                            <br /> 편안한 연습 환경을 제공합니다.
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default BookSetting;
