import React, { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowLeftImg from "../assets/ic-arrow-left-grey.png";
import ArrowRightImg from "../assets/ic-arrow-right-grey.png";
import { constant } from "../constants/constants";
import ContentBox from "./ContentBox";
import NoticeCard from "./NoticeCard";

const MainSection05 = ({ isMobile = false }) => {
    const items = constant.mainsection05_slider;

    const swiperRef = useRef(null);
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const handlePrev = () => {
        if (swiperRef.current) {
            swiperRef.current.slidePrev(); // 이전 슬라이드로 이동
        }
    };

    const handleNext = () => {
        if (swiperRef.current) {
            swiperRef.current.slideNext(); // 다음 슬라이드로 이동
        }
    };

    return (
        <div className="sg-main-section-05-container">
            <ContentBox>
                <div className="sg-main-section-05-title-box">
                    사각에서 전하는 <br />
                    소식을 만나보세요.
                </div>
            </ContentBox>

            {!isMobile && (
                <div className="md:sg-main-section-05-slider-container-md">
                    <button
                        onClick={handlePrev}
                        className={`sg-main-section-04-slider-btn ${
                            isBeginning ? "disabled" : null
                        }`}
                    >
                        <img src={ArrowLeftImg} alt="슬라이드 왼쪽 버튼" />
                    </button>
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={24}
                        slidesPerView={3}
                        onSwiper={(swiper) => {
                            swiperRef.current = swiper;
                            // 초기 상태 설정
                            setIsBeginning(swiper.isBeginning);
                            setIsEnd(swiper.isEnd);
                        }}
                        onSlideChange={(swiper) => {
                            // 슬라이드 변경 시 상태 업데이트
                            setIsBeginning(swiper.isBeginning);
                            setIsEnd(swiper.isEnd);
                        }}
                    >
                        {items.map((item, idx) => (
                            <SwiperSlide key={idx}>
                                <NoticeCard item={item} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <button
                        onClick={handleNext}
                        className={`sg-main-section-04-slider-btn ${isEnd ? "disabled" : null}`}
                    >
                        <img src={ArrowRightImg} alt="슬라이드 오른쪽 버튼" />
                    </button>
                </div>
            )}

            {isMobile && (
                <ContentBox>
                    <div className="flex gap-[16px] overflow-x-scroll w-full items-stretch">
                        {items.map((item, idx) => (
                            <NoticeCard item={item} isMobile={isMobile} />
                        ))}
                    </div>
                </ContentBox>
            )}
        </div>
    );
};

export default MainSection05;
